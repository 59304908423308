import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <section className="contact-head">
      <div className="container">
        <div className="contact-intro">
        </div>
        <div className="contact-content">
          <p className="contact-text">If you'd like to talk about a job or a project, feel free to send me a message.</p>
          <form name="contact" method="post" netlify-honeypot="bot-field" data-netlify-recaptcha="true" data-netlify="true">
            <input type="hidden" name="bot-field" />
            <label>
              Name
              <input type="text" name="name" id="name" />
            </label>
            <label>
              Email
              <input type="email" name="email" id="email" />
            </label>
            <label>
              Subject
              <input type="text" name="subject" id="subject" />
            </label>
            <label>
              Message
              <textarea name="message" id="message" rows="5" />
            </label>
            <div data-netlify-recaptcha="true"></div>
            <button type="submit">Send</button>
          </form>
        </div>
      </div>
    </section>
  </Layout>
);
  
  export default ContactPage
